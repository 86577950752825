<template>
  <div>
    <div class="block banner bg pip">
      <div class="container">
        <div class="box">
          <h1>{{$t('home36')}}</h1>
          <p>{{$t('product1')}}</p>
          <p class="mobile des">
            {{$t('product3')}}</p>
          <el-button type="primary">{{$t('consultingBusiness')}}</el-button>
        </div>
      </div>
    </div>
    <div class="block block1 pc">
      <div class="container">
        <el-row>
          <el-col :span="17">
            <h2>{{$t('product2')}}</h2>
            <p>
              {{$t('product3')}}<br/>
              •	{{$t('product4')}}<br/>
              •	{{$t('product5')}}<br/>
              •	{{$t('product6')}}
            </p>
          </el-col>
          <el-col :span="7">
            <OtherList hideRoute="productddos" />
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="block gray">
      <div class="container">
        <h2>{{$t('product7')}}</h2>
        <ul class="list1">
          <li class="icon12">
            <h5>{{$t('product8')}}</h5>
            <p>{{$t('product9')}}</p> 
          </li>
          <li class="icon34">
            <h5>{{$t('product10')}}</h5>
            <p>{{$t('product11')}}</p> 
          </li>
          <li class="icon41">
            <h5>{{$t('product12')}}</h5>
            <p>{{$t('product13')}}</p>  
          </li>
          <li class="icon42">
            <h5>{{$t('product14')}}</h5>
            <p>{{$t('product15')}}</p>  
          </li>
          <li class="icon44">
            <h5>{{$t('product16')}}</h5>
            <p>{{$t('product17')}}</p>  
          </li>
          <li class="icon13">
            <h5>{{$t('product18')}}</h5>
            <p>{{$t('product19')}}</p>  
          </li>
        </ul>
      </div>
    </div>
    <div class="block">
      <div class="container">
        <ul class="product-list">
          <li>
            <div class="img"><img src="@/assets/images/image8.svg" /></div>
            <div class="cont">
              <h4>{{$t('product20')}}</h4>
              <p>{{$t('product21')}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="block gray">
      <div class="container">
        <ul class="product-list">
          <li class="left">
            <div class="img"><img src="@/assets/images/image9.svg" /></div>
            <div class="cont">
              <h4>{{$t('product22')}}</h4>
              <p>{{$t('product23')}}</p>
              <div class="tags">
                <div class="item bg icon60">{{$t('product24')}}</div>
                <div class="item bg icon61">{{$t('product25')}}</div>
                <div class="item bg icon62">{{$t('product26')}}</div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="block">
      <div class="container">
        <ApplyBox />
      </div>
    </div>
  </div>
</template>
<script>
  import OtherList from '@/components/OtherList.vue'
  import ApplyBox from '@/components/ApplyBox.vue'
export default {
  name: "ProductDdos",
  components: {
    OtherList,
    ApplyBox
  },
  mounted() {
  },
  data() {
    return {
    }
  },
  metaInfo() {
    return {
      title: '「BoxCDN」'+this.$t('home36')+'-'+this.$t('home80')
    }
  }
}
</script>
<style lang="scss" scoped>
  .banner {
    background-image: url('~@/assets/images/banner2.jpg');
  }

  .list1 {
    li {
      width: 33.33%;
    }
  }
</style>
